<template>
  <section class="ant-layout site-layout">
    <header class="ant-layout-header site-header">
      <div class="flex-between i-back-normal-head">
        <div class="i-back-head-left">
          <span class="i-back-normal-head-text">消息</span>
        </div>
        <div class="i-back-normal-head-right-text"></div>
      </div>
    </header>
    <main class="ant-layout-content main-content">
      <div class="bulletin flex-column">
        <div class="bulletin-head i-card flex-row">
          <div @click="switchType(1)" :class="{'bulletin-head-active': type == 1}" class="bulletin-head-normal flex-column-center">
            <img src="../../assets/static/icon_activity.031dec07.svg" />
            <span>活动</span>
          </div>
          <div @click="switchType(2)" :class="{'bulletin-head-active': type == 2}" class="bulletin-head-normal flex-column-center">
            <img src="../../assets/static/icon_system.28d7768d.svg" />
            <span>系统</span>
          </div>
        </div>
        <div class="message i-special-card">
          <div class="message-content flex-column">
            <div class="message-content-head flex-between">
              <div class="message-content-head-left">
                <span @click="switchTab(0)" :class="{'message-content-head-active': tabIndex == 0}">全部 ({{notifyData.allCount}})</span>
                <span @click="switchTab(1)" :class="{'message-content-head-active': tabIndex == 1}">未读 ({{notifyData.noReadCount}})</span>
              </div>
            </div>
            <div class="custom-message-box">
              <div class="custom-message-content-box">
                <div v-for="notify in notifyList" :key="notify.id" @click="gotoNotifyDetail(notify)">
                  <div class="title">{{notify.title}}</div>
                  <div class="content">{{notify.content}}</div>
                </div>
              </div>
              <Page v-if="pageTotal > 1" :total="total" :current="page" :page-size="size" @on-change="handlePageChange" @on-page-size-change='handlePageSize' show-total show-elevator show-sizer class="page-custom-ui"/>
            </div>
            <div v-if="total == 0" class="message-content-empty flex-center">
              <div class="message-content-empty-box">
                <img src="../../assets/static/empty_data.7dd60602.svg" alt="" />
                <span>暂无消息</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
export default {
  name: "bulletin",
  data() {
    return {
       type: 1,
       tabIndex: 0,
       total: 0,
       page: 1,
       size: 20,
       pageTotal: 0,
       notifyList: [],
       notifyData: {
         allCount: 0,
         noReadCount: 0
       }
    }
  },
  created(){
    this.getNotifyData();
    this.getNotifyList();
  },
  methods: {
    switchTab(tabIndex){
       this.tabIndex = tabIndex;
       this.getNotifyList();
    },
    switchType(type){
       this.type = type;
       this.getNotifyData();
       this.getNotifyList();
    },
    getNotifyData(){
       this.$http.get('/api/user/getNotifyData',{type: this.type},{loading: false}).then(data=>{
          this.notifyData = data;
       });
    },
    getNotifyList(){
       this.$http.get('/api/user/getNotifyList',{type: this.type,readStatus: this.tabIndex,pn: this.page,ps: this.size}).then(data=>{
          this.notifyList = data.list;
          this.page = data.pageNum;
          this.size = data.pageSize;
          this.total = data.total;
          this.pageTotal = data.pages;
       });
    },
    gotoNotifyDetail(notify){
       this.$router.push({name: 'bulletinDetail',query: {id: notify.id}}); 
    },
    handlePageChange(page){
       this.page = page;
       this.getNotifyList();
    },
    handlePageSize(size){
       this.size = size;
       this.page = 1;
       this.getNotifyList();
    }
  }
};
</script>
<style scoped>
.bulletin {
  width: 100%;
  height: 100%;
  background: #f7f9fc;
}
</style>
<style scoped>
.custom-message-box{
  padding: 16px 24px;
}
.custom-message-content-box{
  margin: 0 0 16px 0;
}
.custom-message-content-box > div{
  border-bottom: 1px solid #eee;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.custom-message-content-box .title{
  font-size: 15px;
  font-weight: bold;
}
.custom-message-content-box .content{
  font-size: 13px;
  color: #aaa;
}
</style>